/* 
  This is an autogenerated file, any changes will be overwritten on build

  This file is inspired by SvelteKit's $service-worker module: https://kit.svelte.dev/docs/modules#$service-worker-version

  It's a way to track the version of the app, which is calculated using the timestamp at time of build.
  This is useful for tools like DataDog where the timestamp is necessary for tracking RUM metrics + using sourcemaps.
*/

module.exports = { version: "v1.1728488838" };
